<template>
  <div class="container">
    <main>
      <!-- 因JS无法关闭浏览器，不再需要该按钮 -->
      <!-- <div class="exit">
                <button @click="$router.go(-1)">
                    <i class="icon-leftarrow"></i>退出問診
                </button>
            </div> -->
      <div class="chat">
        <div class="user-camera" id="remote-camera" />
        <div id="local-camera">
          <div class="close-notes" v-if="cameraClosed">鏡頭已關閉</div>
        </div>
        <!-- 摄像头icon -->
        <button style="padding: 11px" class="switch-cam" @click="switchCamera">
          <img src="../../assets/img/camera_btn.png" alt="" />
          <img
            v-if="cameraClosed"
            src="../../assets/img/slash_red.png"
            class="setSlash"
            alt=""
          />
        </button>
        <!-- 声音icon -->
        <button
          style="margin-left: 80px; padding: 10px"
          class="switch-cam"
          :class="{
            slash: docSetMute,
          }"
          @click="switchDocMute"
        >
          <img src="../../assets/img/mute-false.png" alt="" />
        </button>
        <!-- 资料 和消息 icon -->
        <button
          v-if="!openAttachment"
          class="open-attachment"
          @click="openChment"
        >
          <img src="../../assets/img/attachment_btn.png" alt="" />
        </button>
        <button v-else class="open-attachment" @click="openChat">
          <img
            v-if="!isHaveNewsDoc"
            src="../../assets/img/chat_btn.png"
            alt=""
          />
          <img
            v-if="isHaveNewsDoc"
            src="../../assets/img/mesg_true.png"
            style="padding: 14px"
            alt=""
          />
        </button>
      </div>

      <doctor-chat
        v-show="!openAttachment"
        class="info"
        :roomId="roomId"
        @newMessageDoc="isHaveNewsDoc = true"
        :doctorAvatar="doctorAvatar"
      ></doctor-chat>
      <doctor-attachment
        class="info"
        v-show="openAttachment"
        :caseId="caseId"
        :roomId="roomId"
        type="video"
      ></doctor-attachment>
    </main>

    <el-alert
      class="show_watting"
      :title="
        isShowComeIn == false ? '等待病人進入房間,請稍等' : '問診室已連接'
      "
      type="warning"
      :closable="false"
    >
    </el-alert>
  </div>
</template>

<script>
import { chatroomsDetail, chatroomslogin } from "@/api/chat.js";
import doctorChat from "./doctorChatroomComponents/doctorChat.vue";
import { mapState } from "vuex";
import DoctorAttachment from "./doctorChatroomComponents/doctorAttachment.vue";
import clinicMixin from "./chatroomMixins/clinicMixin";
export default {
  mixins: [clinicMixin],
  components: { doctorChat, DoctorAttachment },
  data: function () {
    return {
      roomId: -1,
      caseId: -1,
      openAttachment: false,
      type: "video",
      docSetMute: false,
      isHaveNewsDoc: false,
      isShowComeIn: false,
      setTime: null,
    };
  },
  computed: {
    ...mapState({
      userSig: (state) => state.doctor.userSig,
      userID: (state) => state.doctor.userId,
    }),
  },
  methods: {
    comein() {
      this.setTimer = setInterval(() => {
        this.isShowComeIn = this.isComeInHourse;
      }, 1000);
    },
    openChment() {
      this.openAttachment = true;
      this.isHaveNewsDoc = false;
    },
    openChat() {
      this.openAttachment = false;
      this.isHaveNewsDoc = false;
    },
    switchDocMute() {
      this.docSetMute = !this.docSetMute;
      if (!this.docSetMute) {
        this.rtc.localStream.unmuteAudio(); //開啓聲音
      } else {
        this.rtc.localStream.muteAudio(); //關閉聲音
      }
    },
    chatroomsDetail,
    chatroomslogin,
  },
  created() {
    // Case data, room data相关操作
    this.getChatroomDetail(this.$route.query.room).then((res) => {
      let roomData = res.data;
      this.setChatroomData(roomData);
      this.login({
        userId: roomData.doctorUserId,
        userSig: roomData.doctorUserSig,
        token: roomData.doctorToken,
      });
      // TODO: 后续可能还要加入诊所职员的id
      this.tim.receivedAccount = [roomData.doctorUserId];
      /* 此处的isVideoClosed是指问诊室的视频功能关闭
       * 但是医生/客人仍旧可以进入问诊室内发送
       * 消息。与此处相似的还有
       * "@/views/chatroom/videoClinic.vue"
       * 参考文档：
       * https://www.teambition.com/project/60989e15945ed0947b35a031/app/5eba5fba6a92214d420a3219/workspaces/60b604927ccb9e0046c71f51/docs/60e8150641cef600017a8c8c
       */
      // TRTC初始化
      if (!this.isVideoClosed) {
        this.TRTCInit();
      }
      if (!this.isChatroomClosed) {
        this.prepareSDK();
      }
    });
    this.comein();
  },
};
</script>

<style>
.el-alert__title {
  padding-top: 5px;
  font-size: 20px;
}
</style>
<style lang="scss" scoped>
.setMain {
  position: absolute;
  width: 100%;
  top: 0%;
  bottom: 50px;
}
.show_watting {
  position: absolute;
  left: 0%;
  bottom: 0%;
}
.setSlash {
  position: absolute;
  padding: 11px;
}
.user-camera {
  position: absolute;
  //   right: 0px;
  width: 100%;
  height: 100%;
  background-color: $green-200;
}
.container {
  flex-direction: column;
  display: flex;
  width: 100vw;
  min-width: 1024px;
  height: 100vh;
  background-color: $green-200;
  padding: 32px;
  @include flexr-center-stretch;
  .close-notes {
    color: white;
    height: 24px;
    line-height: 24px;
    text-align: center;
    width: 80px;
    position: absolute;
    top: 63px;
    left: 35px;
    z-index: 100;
  }
  main {
    flex-grow: 1;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 300px;
    grid-template-areas: "chat info";
    div.exit button {
      color: white;
      font-size: 24px;
      line-height: 34px;
      padding: 8px 0;
      background-color: transparent;
    }
    div.chat,
    div.info {
      background-color: white;
      position: relative;
    }
    div.chat {
      grid-area: chat;
      background-color: $white;
      position: relative;
      button {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: white;
        color: $green-300;
        position: absolute;
        bottom: 16px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .switch-cam::after {
        content: "";
        width: 30px;
        height: 2px;
        background-color: $red;
        top: 24px;
        left: 10px;
        position: absolute;
        transform: rotateZ(45deg) scaleX(0);
      }
      .switch-cam.slash::after {
        transform: rotateZ(45deg) scaleX(1);
      }
      .switch-cam {
        left: 16px;
      }
      .open-attachment {
        right: 16px;
      }
      #local-camera {
        width: 150px;
        height: 150px;
        background-color: black;
        position: absolute;
        right: 16px;
        top: 16px;
      }
    }
    div.info {
      grid-area: info;
    }
    div.exit {
      grid-area: exit;
    }
  }
}
</style>